import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components

  // sections
})

Alpine.start()
